// Import your CSS/SCSS files here:

body {
  margin: 0;
  padding: 0;
  font-family: "gourmet_le_frenchregular";
}
@font-face {
  font-family: "gourmet_le_frenchregular";
  src: url("fonts/gourmet_le_french-webfont.woff2") format("woff2"),
    url("fonts/gourmet_le_french-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
p {
  font-size: responsive 16px 22px;
  line-height: responsive 16px 22px;
}
.jakarta {
  font-family: "Plus Jakarta Sans", sans-serif;
  p {
    line-height: 29px;
    font-size: 22px;
    font-weight: 300;
  }
}
h1,
.h1,
h2,
.h2 {
  font-weight: 400;
  font-family: "gourmet_le_frenchregular";
  font-size: responsive 30px 57px;
  line-height: responsive 33px 70px;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}
h3 {
  font-weight: 400;
  font-family: "gourmet_le_frenchregular";
  font-size: responsive 24px 30px;
  line-height: responsive 26px 35px;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}
.booking {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    z-index: 200;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.7);
  }
}
.booking.active {
  display: block;
  z-index: 300;
}
.tommy {
  position: relative;
  padding-top: 100px;
  text-align: right;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 340px;
    border-bottom: 1px solid white;
  }
}
.nana {
  position: relative;
  padding-top: 100px;
  text-align: right;
  margin-right: 40px;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 450px;
    border-bottom: 1px solid white;
  }
}
.book-btn-nav {
  @media (max-width: 1536px) {
    position: fixed;
    bottom: 1rem;
    left: 50%;
    background-color: #0f1813 !important;
    transform: translateX(-50%);
  }
}
.ben {
  position: relative;
  padding-top: 100px;
  text-align: right;
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 640px;
    border-bottom: 1px solid white;
  }
}
.center-body {
  h4 {
    margin-bottom: 2rem;
    font-size: 26px;
  }
  p {
    margin-bottom: 2rem;
  }
  ul {
    li {
      margin-bottom: 2rem;
      font-size: 22px;
    }
  }
}
